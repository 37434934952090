import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, InteractionType, LogLevel, ProtocolMode } from '@azure/msal-browser';

export const CLIENT_ID = {
  local: '163f8684-2491-4315-aaaa-1ba18a68de03',
  dev: '0b5e766b-c46b-47f9-b39f-2a4b376eb332',
  int: '163f8684-2491-4315-aaaa-1ba18a68de03',
  qa: 'e2277e28-d2c3-43e8-a14d-a6818454e540',
  'demo-vlp': '163f8684-2491-4315-aaaa-1ba18a68de03',
  'qa-beta': 'e2277e28-d2c3-43e8-a14d-a6818454e540',
  qabeta: 'e2277e28-d2c3-43e8-a14d-a6818454e540',
  perf: '76fd9676-bf3f-4c29-807e-24f2b5733c31',
  stg: 'cf2ab9e3-a46e-4744-be2c-a2f354299f90',
  prod: '2a672db6-19d1-4fa7-b7f5-c70cc7211928'
};

export const HOSTNAME_TO_ENV_CONFIG = {
  local: 'local',
  'dev-beta': 'dev',
  dev: 'dev',
  int: 'int',
  'qa-beta': 'qa',
  qa: 'qa',
  stg: 'stg',
  stage: 'stg',
  perf: 'perf',
  'demo-vlp': 'int',
  'beta-catprod': 'prod',
  prod: 'prod'
};

export const env =
  Object.keys(HOSTNAME_TO_ENV_CONFIG).find((host: string) => new URL(window.location.href).host.indexOf(host) !== -1) ||
  HOSTNAME_TO_ENV_CONFIG.prod;

export const APP_ENV = HOSTNAME_TO_ENV_CONFIG[env];

const lowerEnvAuthUrl = 'https://signin.cat.com/cwslogin.onmicrosoft.com/b2c_1a_p2_v1_signin_staging/v2.0/';

const higherEnvAuthUrl = 'https://signin.cat.com/cwslogin.onmicrosoft.com/b2c_1a_p2_v1_signin_prod/v2.0/';

const AUTHORIZATION_URL = {
  local: lowerEnvAuthUrl,
  devint: lowerEnvAuthUrl,
  dev: lowerEnvAuthUrl,
  int: lowerEnvAuthUrl,
  qa: lowerEnvAuthUrl,
  perf: lowerEnvAuthUrl,
  stg: higherEnvAuthUrl,
  prod: higherEnvAuthUrl
};

const redirectUri = new URL(window.location.href).origin;
const protectedResourceMap = new Map<string, Array<string>>();
export const scopes = ['openid', 'profile', CLIENT_ID[APP_ENV as keyof typeof CLIENT_ID], 'offline_access']; //pkce: scope to be dynamic based on client ID
const knownAuthorities = ['https://signin.cat.com', 'https://cwslogin.b2clogin.com', 'https://login.microsoftonline.com'];
const authority = AUTHORIZATION_URL[APP_ENV];
const loginFailedRoute = '/?error=login-failed';
protectedResourceMap.set(redirectUri, scopes);
protectedResourceMap.set('/', scopes);

export const authorization = 'authorization';

export const ClientApplicationConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID[APP_ENV as keyof typeof CLIENT_ID],
    knownAuthorities,
    redirectUri,
    authority,
    postLogoutRedirectUri: '/',
    protocolMode: ProtocolMode.OIDC
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false
    }
  }
};

export const MsalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap
};

export const MsalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes,
    extraQueryParameters: { application: '/OneCat', dc: 'us-all' }
  },
  loginFailedRoute
};

export const AUTH_TOKEN_SKIP_CONFIG = ['amazonaws'];

export const MSAL_INTERACTION_STATUS = 'msal.interaction.status';

export const MSAL_TOKEN_KEY = `msal.token.keys.${CLIENT_ID[APP_ENV]}`;
