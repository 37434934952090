import { UserInfo } from '@Terra/shared/widgets/interface';
import { AUTH_TOKEN_SKIP_CONFIG, MSAL_TOKEN_KEY } from './pkce-auth.config';

export const getTokenExpiredorNot = (token: string): boolean => {
  const _accessToken = token;
  if (_accessToken) {
    return getIfTokenExpired(_accessToken);
  }
  return false;
};

export const getIfTokenExpired = (token: string): boolean => {
  const tokenExptime = jwtParser(token)?.exp;
  const _time = new Date().getTime(); // Current time in milliseconds
  const currentTime = Math.floor(_time / 1000); // Converting to same format provided by JWT expiry date
  return currentTime >= tokenExptime;
};

export const checkIfUrlIncludesMsalSkipConfig = (url: string, urlWithParams: string): boolean => {
  for (const item of AUTH_TOKEN_SKIP_CONFIG) {
    if (url.includes(item) || urlWithParams.includes(item)) {
      return true;
    }
  }
  return false;
};

export const jwtParser = (jwtToken: string): UserInfo => {
  try {
    const { 1: base64Payload } = jwtToken.split('.');
    const payload = window.atob(base64Payload);
    return JSON.parse(payload.toString());
  } catch (error) {
    throw new Error(error);
  }
};

export const getTokenFromLocalStorage = (): string => {
  const msalTokenKey = JSON.parse(localStorage.getItem(MSAL_TOKEN_KEY));
  const msalToken = JSON.parse(localStorage.getItem(msalTokenKey['accessToken']));
  return msalToken ? msalToken?.['secret'] : null;
};
