
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-msal-redirect',
  standalone: true,
  imports: [],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MSalRedirectComponent implements OnInit {
  constructor(private authService: MsalService) {}
  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
  }
}
