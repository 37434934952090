import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest
} from '@azure/msal-browser';
import { UserInfo } from '@Terra/shared/widgets/interface';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { scopes } from './pkce-auth.config';
import { getTokenFromLocalStorage, jwtParser } from './pkce-auth.helper';
@Injectable({
  providedIn: 'root'
})
export class PkceAuthService implements OnDestroy {
  isloggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  msalInProgressNone$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  msaluserInfo$: BehaviorSubject<UserInfo> = new BehaviorSubject(null);
  private readonly onDestroy$: Subject<void> = new Subject<void>();
  constructor(
    @Optional() public authService: MsalService,
    @Optional() public msalBroadCastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration
  ) {}

  initializeAuthService(): Promise<string> {
    return new Promise((resolve: (a: string) => void, reject: (a: Error) => void) => {
      if (this.authService) {
        this.authService.initialize().subscribe(() => {
          resolve('initialised');
        });
      } else {
        reject(new Error('authService not available'));
      }
    });
  }

  handlePkceSubjects(): void {
    this.msalSubjectSubscription();
    this.msalInProgressSubscription();
  }

  msalSubjectSubscription(): void {
    this.msalBroadCastService.msalSubject$.pipe(takeUntil(this.onDestroy$)).subscribe((res: EventMessage) => {
      if (res.eventType === EventType.LOGIN_SUCCESS) {
        this.authService.instance.setActiveAccount((res.payload as AuthenticationResult).account);
      }
    });
  }

  msalInProgressSubscription(): void {
    this.msalBroadCastService.inProgress$.pipe(takeUntil(this.onDestroy$)).subscribe((res: string) => {
      if (res === InteractionStatus.None) {
        this.msalInProgressNone$.next(true);
      }
    });
  }

  getActiveAccount(): AccountInfo {
    return this.authService.instance.getActiveAccount();
  }

  getAllAccounts(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  getUserInfoFromToken(): void {
    const userInfo = getTokenFromLocalStorage();
    userInfo ? this.updateUserInfo(userInfo) : this.getSlientToken();
  }

  getSlientToken(): void {
    const accessTokenRequest = {
      scopes
    };
    this.authService.instance
      .acquireTokenSilent(accessTokenRequest)
      .then((msalUserInfo: AuthenticationResult) => {
        this.updateUserInfo(msalUserInfo.idToken);
      })
      .catch((error: HttpErrorResponse) => this.msaluserInfo$.error(error));
  }

  updateUserInfo(token: string): void {
    this.msaluserInfo$.next(jwtParser(token));
  }

  setIsLoggedInValue(value: boolean = true): void {
    this.isloggedIn$.next(value);
  }

  loginRedirect(): void {
    this.authService.loginRedirect({
      ...this.msalGuardConfig.authRequest
    } as RedirectRequest);
  }

  logoutRedirect(): void {
    this.authService.logoutRedirect({ ...this.msalGuardConfig.authRequest } as EndSessionRequest);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
