import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalInterceptor } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { checkIfUrlIncludesMsalSkipConfig } from './pkce-auth.helper';

@Injectable()
export class MsalSkipInterceptor extends MsalInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (checkIfUrlIncludesMsalSkipConfig(request.url, request.urlWithParams)) {
      return next.handle(request);
    }
    return super.intercept(request, next);
  }
}
