import { LogOut, SharedLibState } from '@Terra/shared/shared-lib';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { authorization } from './pkce-auth.config';
import { getTokenExpiredorNot } from './pkce-auth.helper';

@Injectable()
export class PkceAuthInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<SharedLibState>) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap({
        next: (event: HttpHeaderResponse) => {
          //other than 401 error status, next interceptor is called for other error codes and on success scenarios
          if (event) {
            return event.status;
          }
          return null;
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 401) {
            let token = '';
            const requestHeader = req.headers['headers'];
            requestHeader.entries().forEach((value: Array<string>) => {
              if (value[0] === authorization) {
                //entries array has headers value at [0] and bearer token at [1]
                // eslint-disable-next-line prefer-destructuring
                const bearerToken = value[1];
                //token = 'Bearer tokenvalue', here we need only the tokenvalue
                // eslint-disable-next-line prefer-destructuring
                token = bearerToken[0].split(' ')[1];
              }
            });

            const isTokenExpired = getTokenExpiredorNot(token);
            if (isTokenExpired) {
              return true;
            }
            this.store.dispatch(new LogOut(true));
          }
        }
      })
    );
  }
}
